export const Box = {
    borderStyle: "solid",
    borderWidth: "1px",
    // borderColor: "#BF360C",
    // backgroundColor: "#601B06",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
}

export const BoxRed01 = {
    borderColor: "#601B06",
    borderWidth: "0",
    backgroundColor: "#300E03",
}