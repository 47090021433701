import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReactElement } from "react";
import Modal from 'react-bootstrap/Modal';
import { closeApplicationWindow } from "../../actions";
import ThreeD from "./Applications/ThreeD";
import Location from "./Applications/Location";
import Notifications from "./Applications/Notifications";
import Mercury from "./Applications/Mercury";
import Archon from "./Applications/Archon";
import Saturn from "./Applications/Saturn";
import Plutonium from "./Applications/Plutonium";
import Notes from "./Applications/Notes";
import DevTools from "./Applications/DevTools";

interface Props {
}
const ApplicationWindow: React.FC<Props> = () => {
    const applicationStatus = useSelector((store: any) => store.applicationStatus)
    const dispatch: any = useDispatch();

    const handleClose = () => {
        dispatch(closeApplicationWindow(applicationStatus.applicationName));
    }

    const loadApplication = (name: string): ReactElement => {
        switch (name) {
            case "3D":
                return <ThreeD />
            case "Location":
                return <Location />
            case "Notifications":
                return <Notifications />
            case "Mercury":
                return <Mercury />
            case "Saturn":
                return <Saturn />
            case "Archon":
                return <Archon />
            case "Plutonium":
                return <Plutonium />
            case "Notes":
                return <Notes />
            case "Dev Tools":
                return <DevTools />
        }
        return <>(No content for {name})</>
    }

    return (
        <>
            <Modal show={applicationStatus.applicationWindowOpen} onHide={handleClose} dialogClassName="full-height" >
                <Modal.Header className="p-2">
                    <Modal.Title>{applicationStatus.applicationName}</Modal.Title>
                    <button className="btn btn-theme-red-noborder p-0 m-0" onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} size={"2x"} />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {loadApplication(applicationStatus.applicationName)}
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <button className="btn btn-theme-red py-1 px-2" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ApplicationWindow;