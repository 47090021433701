import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faQrcode, faCube, faComputer, faLaptopCode, faVial, faServer, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faDotCircle, faBell, faClipboard } from '@fortawesome/free-regular-svg-icons'
import { openApplicationWindow } from "../../actions";
import ApplicationWindow from "./ApplicationWindow";
import { State } from "../../types";
export const desktopIconClass = "btn me-2 mb-4 mt-2 desktop-icon v2";

interface Props {
}
const Desktop: React.FC<Props> = () => {
    const ws = useSelector((store: State) => store.applicationStatus.workstations);
    const dispatch: any = useDispatch();

    return (
        <>
            <div>Apps</div>

            <button onClick={() => { dispatch(openApplicationWindow("Notes")) }} className={desktopIconClass}>
                <span className="icon"><FontAwesomeIcon style={{ color: "#00BCD4" }} icon={faClipboard} size={"2x"} /></span>
                <span className="title">
                    Notes
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("Mercury")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#1E88E5" }}><FontAwesomeIcon icon={faComputer} size={"2x"} /></span>
                <span className="subicon" style={{ color: ws.includes("mercury") ? "#43A047" : "#4F4F4F" }}><FontAwesomeIcon icon={ws.includes("mercury") ? faCircleCheck : faCircleXmark} size={"1x"} /></span>
                <span className="title">
                    Mercury
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("Saturn")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#FF6F00" }}><FontAwesomeIcon icon={faComputer} size={"2x"} /></span>
                <span className="subicon" style={{ color: ws.includes("saturn") ? "#43A047" : "#4F4F4F" }}><FontAwesomeIcon icon={ws.includes("saturn") ? faCircleCheck : faCircleXmark} size={"1x"} /></span>
                <span className="title">
                    Saturn
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("Archon")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#1E88E5" }}><FontAwesomeIcon icon={faLaptopCode} size={"2x"} /></span>
                <span className="subicon" style={{ color: ws.includes("archon") ? "#43A047" : "#4F4F4F" }}><FontAwesomeIcon icon={ws.includes("archon") ? faCircleCheck : faCircleXmark} size={"1x"} /></span>
                <span className="title">
                    Archon
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("Plutonium")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#4DB6AC" }}><FontAwesomeIcon icon={faLaptopCode} size={"2x"} /></span>
                <span className="subicon" style={{ color: ws.includes("plutonium") ? "#43A047" : "#4F4F4F" }}><FontAwesomeIcon icon={ws.includes("plutonium") ? faCircleCheck : faCircleXmark} size={"1x"} /></span>
                <span className="title">
                    Plutonium
                </span>
            </button>

            <div>Experiments</div>

            <button onClick={() => { dispatch(openApplicationWindow("Location")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#2E7D32" }}><FontAwesomeIcon icon={faLocationDot} size={"2x"} /></span>
                <span className="title">
                    Location
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("Notifications")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#F4511E" }}><FontAwesomeIcon icon={faBell} size={"2x"} /></span>
                <span className="title">
                    Notifications
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("QR Code Reader")) }} className={desktopIconClass}>
                <span className="icon"><FontAwesomeIcon icon={faQrcode} size={"2x"} /></span>
                <span className="title">
                    QR code reader
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("Something")) }} className={desktopIconClass}>
                <span className="icon"><FontAwesomeIcon icon={faDotCircle} size={"2x"} /></span>
                <span className="title">
                    Something
                </span>
            </button>


            <button onClick={() => { dispatch(openApplicationWindow("3D")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#B71C1C" }}><FontAwesomeIcon icon={faCube} size={"2x"} /></span>
                <span className="title">
                    3d
                </span>
            </button>


            <div>Other</div>

            <button onClick={() => { dispatch(openApplicationWindow("Dev Tools")) }} className={desktopIconClass}>
                <span className="icon" style={{ color: "#4DB6AC" }}><FontAwesomeIcon icon={faVial} size={"2x"} /></span>
                <span className="title">
                    Dev tools
                </span>
            </button>

            <button onClick={() => { dispatch(openApplicationWindow("Localhost")) }} className={desktopIconClass} >
                <span className="icon" style={{ color: "#4DB6AC" }}><FontAwesomeIcon icon={faServer} size={"2x"} /></span>
                <span className="subicon" style={{ color: ws.includes("localhost") ? "#43A047" : "#4F4F4F" }}><FontAwesomeIcon icon={ws.includes("localhost") ? faCircleCheck : faCircleXmark} size={"1x"} /></span>

                <span className="title">
                    Localhost
                </span>
            </button>

            <ApplicationWindow />

        </>
    );
}

export default Desktop;