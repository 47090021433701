import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotes, remove, save } from "../../../actions/noteActions";
import { State } from "../../../types";
import { Note } from "../../../../../types";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faFileText } from "@fortawesome/free-regular-svg-icons";

interface Props {
}
const Notes: React.FC<Props> = () => {
    const dispatch: any = useDispatch();
    const notes = useSelector((store: State) => store.notes)
    const [openedNote, setOpenedNote] = useState<Note>();

    useEffect(() => {
        dispatch(getNotes());
    }, [dispatch]);

    const openNewNote = () => {
        const name = window.prompt("Document name", "New note")
        if (!name) { return; }
        setOpenedNote(
            {
                name: name ?? "",
                content: "",
                owner: "",
            }
        )
    }

    return (
        <>
            {
                notes.archive.map(n => <NoteComponent setOpenedNote={setOpenedNote} key={n.id} note={n}/>)
            }
            <button className="btn btn-theme-red py-0 px-1 mt-2" onClick={openNewNote}>
                <FontAwesomeIcon icon={faPlus} size="xs" style={{}} />
                <FontAwesomeIcon icon={faPen} />
            </button>
            <OpenedNoteComponent note={openedNote} setOpenedNote={setOpenedNote} openedNote={openedNote} />
        </>
    )
}

interface NoteProps {
    note: Note;
    setOpenedNote: React.Dispatch<React.SetStateAction<Note | undefined>>;
}
const NoteComponent: React.FC<NoteProps> = (props: NoteProps) => {
    // const dispatch: any = useDispatch();

    return (
        <>
            <div onClick={() => {props.setOpenedNote(props.note)}}><FontAwesomeIcon icon={faFileText} size={"1x"} /> {props.note.name}</div>
        </>
    )
}

interface OpenedNoteProps {
    note?: Note;
    openedNote: Note | undefined;
    setOpenedNote: React.Dispatch<React.SetStateAction<Note | undefined>>;
}
const OpenedNoteComponent: React.FC<OpenedNoteProps> = (props: OpenedNoteProps) => {
    const dispatch: any = useDispatch();

    const [content, setContent] = useState<string>("");
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        setContent(props.note?.content ?? "")
        setIsDirty(false)
    }, [props.note?.content]);

    const onContentChange = (e: React.ChangeEvent<any>) => {
        setContent(e.target.value)
        if (!isDirty) { setIsDirty(true) }
    }

    const onSave = () => {
        props.note && dispatch(save({...props.note, content: content}))
        setIsDirty(false)
        props.setOpenedNote(undefined)
    }

    const onRemove = () => {
        if (window.confirm("Delete note?")) {
            props.note && dispatch(remove({...props.note, content: content}))
            props.setOpenedNote(undefined)
        }
    }

    const close = () => {
        if (isDirty) {
            if (!window.confirm("Exit without saving?")) {
                return;
            }
        }
        props.setOpenedNote(undefined)
    }

    return (
        <Modal show={props.openedNote !== undefined} onHide={close} dialogClassName="full-height" >
            <Modal.Header className="p-2">
                <Modal.Title><FontAwesomeIcon icon={faFileText} size={"1x"} /> {props.note?.name}</Modal.Title>
                <button className="btn btn-theme-red-noborder p-0 m-0" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} size={"2x"} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <textarea className="" value={content} onChange={onContentChange} style={{ height: "100%" }}></textarea>
            </Modal.Body>
            <Modal.Footer className="p-2">
                <button className="btn btn-theme-red-filled py-1 px-2" onClick={onRemove}>
                    Delete
                </button>
                <button className="btn btn-theme-red py-1 px-2" onClick={onSave}>
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default Notes