import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { closeApplicationWindow } from "../../actions";
import { State } from "../../types";
import { Button, Form } from "react-bootstrap";
import { resetPassword } from "../../actions/userActions";
import { useParams } from 'react-router-dom';
import api from "../../api";

interface Props {
}
const Settings: React.FC<Props> = () => {
    const openWindows = useSelector((store: State) => store.applicationStatus.openWindows);
    const dispatch: any = useDispatch();

    const { emailAddress, code } = useParams<{ emailAddress: string, code: string }>();

    const [codeIsValid, setCodeIsValid] = useState<boolean | undefined>(undefined);
    const [username, setUsername] = useState<string>(emailAddress);
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    const checkCode = async () => {
        let res = await api.get(`/api/checkresetpasswordcode/${emailAddress}/${code}`);
        if (res.status === 200) {
            setCodeIsValid(true)
        } else {
            setCodeIsValid(false)
        }
    }

    useEffect(() => {
        if (codeIsValid === undefined) {
            checkCode();
        }
    });

    return (
        <>
            <Modal show={true}>
                <Modal.Header className="p-1">
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1">
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control disabled type="email" value={username} onChange={(e) => { setUsername(e.target.value); }} placeholder="name@example.com" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password1} onChange={(e) => { setPassword1(e.target.value); }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Password again</Form.Label>
                            <Form.Control type="password" value={password2} onChange={(e) => { setPassword2(e.target.value); }} />
                        </Form.Group>
                        <Button disabled={!codeIsValid} onClick={() => { dispatch(resetPassword(username, code, password1, password2)); setPassword1(""); setPassword2(""); }} variant="danger">
                            {codeIsValid ? "Set password" : "Invalid code"}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal backdropClassName="backdrop" show={openWindows.includes("resetPasswordError")} onHide={() => { dispatch(closeApplicationWindow("resetPasswordError")) }}>
                <Modal.Header className="p-1" closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1">
                    Error
                </Modal.Body>
                <Modal.Footer className="p-1">
                    <Button className="px-2 py-0" variant="secondary" onClick={() => { dispatch(closeApplicationWindow("resetPasswordError")) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="mt-5" show={openWindows.includes("resetPasswordChanged")} onHide={() => { dispatch(closeApplicationWindow("resetPasswordChanged")) }}>
                <Modal.Header className="p-1" closeButton>
                    <Modal.Title>Password changed</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1">
                    Password changed
                </Modal.Body>
                <Modal.Footer className="p-1">
                    <Button className="px-2 py-0" variant="secondary" onClick={() => { dispatch(closeApplicationWindow("resetPasswordChanged")) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Settings;