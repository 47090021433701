import { actions, displayResponseError } from ".";
import api from "../api";

export const putToSleep = (workstation: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.inProgressAction(`putToSleep_${workstation}`));
        let res = await api.get(`/api/put-to-sleep/${workstation}`);
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.notInProgressAction(`putToSleep_${workstation}`));
    }
}

export const wakeUp = (workstation: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.inProgressAction(`wakeUp_${workstation}`));
        let res = await api.get(`/api/wake-up/${workstation}`);
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.notInProgressAction(`wakeUp_${workstation}`));
    }
}

export const shutdown = (workstation: string) => {
    return async (dispatch: (arg0: any) => void) => {
        if (!window.confirm("Force shutdown?")) {
            return;
        }
        dispatch(actions.inProgressAction(`shutdown_${workstation}`));
        let res = await api.get(`/api/shutdown/${workstation}`);
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.notInProgressAction(`shutdown_${workstation}`));
    }
}

export const lockWorkstation = (workstation: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.inProgressAction(`lockWorkstation_${workstation}`));
        let res = await api.get(`/api/lock/${workstation}`);
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.notInProgressAction(`lockWorkstation_${workstation}`));
    }
}

export const setSoundDeviceTo = (workstation: string, device: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.inProgressAction(`setSoundDeviceTo_${workstation}_${device}`));
        let res = await api.get(`/api/set-sound-device-to/${workstation}/${device}`);
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.notInProgressAction(`setSoundDeviceTo_${workstation}_${device}`));
    }
}

export const setVolumeTo = (workstation: string, device: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.inProgressAction(`setVolumeTo_${workstation}_${device}`));
        let res = await api.get(`/api/set-volume/${workstation}/${device}`);
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.notInProgressAction(`setVolumeTo_${workstation}_${device}`));
    }
}

export const ping = (workstation: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.inProgressAction(`ping_${workstation}`));
        let res = await api.get(`/api/ping/${workstation}`);
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.notInProgressAction(`ping_${workstation}`));
    }
}

export const emit = (name: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.emitAction({name}));
    }
}