import React from 'react';
import { faHeadphones, faVolumeHigh, faLock, faPowerOff, faEye } from '@fortawesome/free-solid-svg-icons'
import { faMoon } from '@fortawesome/free-regular-svg-icons'
import { useDispatch } from 'react-redux';
import { desktopIconClass } from '..';
import { lockWorkstation, putToSleep, setSoundDeviceTo, shutdown, wakeUp } from '../../../actions/api';
import Icon from './utils/Icon';

interface Props {
}
const Mercury: React.FC<Props> = () => {
    const dispatch: any = useDispatch();

    return (
        <>
            <div className="">

                <button onClick={() => { dispatch(lockWorkstation("mercury")) }} className={desktopIconClass}>
                    <span className="icon" style={{ color: "#1E88E5" }}>
                        <Icon icon={faLock} inProgressId="lockWorkstation_mercury" />
                    </span>
                    <span className="title">
                        Lock
                    </span>
                </button >

                <button onClick={() => { dispatch(putToSleep("mercury")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faMoon} inProgressId="putToSleep_mercury" />
                    </span>
                    <span className="title">
                        Sleep
                    </span>
                </button>

                <button onClick={() => { dispatch(shutdown("mercury")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faPowerOff} inProgressId="shutdown_mercury" />
                    </span>
                    <span className="title">
                        Shutdown
                    </span>
                </button>

                <button onClick={() => { dispatch(wakeUp("mercury")) }} className={desktopIconClass}>
                    <span className="icon" style={{ color: "#1E88E5" }}>
                        <Icon icon={faEye} inProgressId="wakeUp_mercury" />
                    </span>
                    <span className="title">
                        Wake up
                    </span>
                </button>

            </div>

            <div className="">
                <button onClick={() => { dispatch(setSoundDeviceTo("mercury", "HEADPHONES")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faHeadphones} inProgressId="setSoundDeviceTo_mercury_HEADPHONES" />
                    </span>
                    <span className="title">
                        Headphones
                    </span>
                </button>

                <button onClick={() => { dispatch(setSoundDeviceTo("mercury", "OPTICAL OUT")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeHigh} inProgressId="setSoundDeviceTo_mercury_OPTICAL OUT" />
                    </span>
                    <span className="title">
                        Optical Out
                    </span>
                </button>

            </div>
        </>
    );
}

export default Mercury;