import axios from "axios";
import { getAppInstallationId, getSocketId } from "../helpers/authorizationHelpers";
// import config from "../../../data/static/config.json"

let baseURL = '/';
if (window.location.host.includes("localhost")) {
    baseURL = `http://localhost:9500/`;
}

// const url = window.location.hash.split('/');
// const appInstallationId = url[2]

export default axios.create({
    baseURL: baseURL,
    validateStatus: () => true,
    headers: {
        appinstallationid: getAppInstallationId(),
        socketId: getSocketId(),
    }
});