import { useDispatch, useSelector } from "react-redux";
import {
    Link
} from "react-router-dom";
import { Box, BoxRed01 } from "../../styles/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faHouseUser, faGear, faCircleInfo, faUserSlash, faUser, faPlugCircleExclamation, faPlugCircleCheck, faPlugCircleBolt } from '@fortawesome/free-solid-svg-icons'
import { State } from "../../types";
import { closeWindow, openWindow } from "../../actions";
import { Modal, Form, Button } from "react-bootstrap";
import { login } from "../../actions/userActions";
import { useState } from "react";

function Header() {
    const userStatus = useSelector((store: State) => store.userStatus)
    const openWindows = useSelector((store: State) => store.applicationStatus.openWindows);
    const settings = useSelector((store: State) => store.settings);
    const dispatch: any = useDispatch();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    return (
        <>
            <div className="px-2" style={{
                height: "50px",
                lineHeight: "50px",
                ...Box,
                ...BoxRed01,
            }}>
                <div className="dropdown">
                    <button className="btn btn-theme-red" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {/* <button className="btn btn-theme-red" id="dropdownMenuButton1" onClick={() => { setMenuVisible(!menuVisible) }} aria-expanded="false"> */}
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <ul className="dropdown-menu dropdown-theme-red animate slideIn w-100" aria-labelledby="dropdownMenuButton1" style={{ lineHeight: 1.5 }}>
                        {/* <ul className={"dropdown-menu dropdown-theme-red animate slideIn w-100" + (menuVisible ? " show" : " d-none")} style={{ lineHeight: 1.5, marginTop: "50px !important", top: "50px" }} data-bs-toggle="dropdown"> */}
                        <li><Link className="dropdown-item px-2 py-1" to="./"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faHouseUser} /></span><span className="">Home</span></Link></li>
                        <li><Link className="dropdown-item px-2 py-1" to="./settings"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faGear} /></span><span className="">Settings</span></Link></li>
                        <li><Link className="dropdown-item px-2 py-1" to="./about"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faCircleInfo} /></span><span className="">About</span></Link></li>
                    </ul>
                    <span className="float-end px-1">
                        {!userStatus.installationActive ?
                            <FontAwesomeIcon onClick={() => { dispatch(openWindow("login")) }} className="ms-2 text-danger" icon={faUserSlash} size={"1x"} />
                            :
                            <FontAwesomeIcon onClick={() => { dispatch(openWindow("user")) }} className="ms-2" icon={faUser} size={"1x"} />
                        }
                    </span>
                    <span className="float-end px-1">
                        { settings.socketStatus === "disconnected" && <FontAwesomeIcon className="ms-2 text-danger" icon={faPlugCircleExclamation} size={"1x"} /> }
                        { settings.socketStatus === "connected" && <FontAwesomeIcon className="ms-2 text-danger" icon={faPlugCircleCheck} size={"1x"} /> }
                        { settings.socketStatus === "authenticated" && <FontAwesomeIcon className="ms-2 text-danger" icon={faPlugCircleBolt} size={"1x"} /> }
                    </span>
                </div>

                <Modal show={openWindows.includes("login")} onHide={() => { dispatch(closeWindow("login")) }}>
                    <Modal.Header className="p-1" closeButton>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-1">
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" value={username} onChange={(e) => { setUsername(e.target.value); }} placeholder="name@example.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" value={password} onChange={(e) => { setPassword(e.target.value); }} />
                            </Form.Group>
                            <Button className="w-100" onClick={() => { dispatch(login(username, password)); setUsername(""); setPassword(""); }} variant="danger">Login</Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="p-1">
                        <Button className="" variant="secondary" onClick={() => { dispatch(closeWindow("login")) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    );
}

export default Header;