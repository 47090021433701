import { faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useSelector } from 'react-redux';
import { State } from '../../../../types';

interface Props {
    icon: IconDefinition,
    inProgressId: string,
}
const Icon: React.FC<Props> = (props) => {

    const inProgress = useSelector((store: State) => store.inProgress);

    return (
        <FontAwesomeIcon
            spin={inProgress.indexOf(props.inProgressId) > -1}
            icon={inProgress.indexOf(props.inProgressId) > -1 ? faSpinner : props.icon}
            size={"2x"}
        />
    );
}

export default Icon