import React from 'react';
import { faSatelliteDish, faPlug } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux';
import { desktopIconClass } from '..';
import { emit, ping } from '../../../actions/api';
import Icon from './utils/Icon';

interface Props {
}
const DevTools: React.FC<Props> = () => {
    const dispatch: any = useDispatch();

    return (
        <>
            <div className="">

                <button onClick={() => { dispatch(ping("localhost")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faSatelliteDish} inProgressId="" />
                    </span>
                    <span className="title">
                        Ping localhost
                    </span>
                </button>

                <button onClick={() => { dispatch(emit("logSockets")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faPlug} inProgressId="" />
                    </span>
                    <span className="title">
                        Print sockets to console
                    </span>
                </button>

            </div>

        </>
    );
}

export default DevTools;