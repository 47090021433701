import React, { useEffect, useRef, useState } from 'react';
// import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';

interface Props {
}
const Location: React.FC<Props> = () => {

    let [permissionGranted, setPermissionGranted] = useState<boolean>(false);

    useEffect(() => {
        console.log("Notification")
        Notification.requestPermission().then((result) => {
            if (result === "granted") {
                setPermissionGranted(true);
            }
        });
    }, []);

    const sendNotification = () => {
        console.log("Sending notification in 1 sec")
        setTimeout(async () => {
            console.log("Sending notification...")
            try {
                // const ready = await navigator.serviceWorker.ready;
                console.log(navigator.serviceWorker);
                const reg = await navigator.serviceWorker.getRegistration();
                console.log("REG!");
                console.log(reg);
                if (!reg) {
                    // alert("Service worker registration is null");
                    return;
                }
                navigator.serviceWorker.ready.then((registration) => {
                    console.log("Service worker ready");
                    registration.showNotification("Notification", {
                        body: "Yeah, notification!",
                        // icon: "../images/touch/chrome-touch-icon-192x192.png",
                        vibrate: [200, 100, 200, 100, 200, 100, 200],
                        tag: "vibration-sample",
                    });
                });
                // let sw = new ServiceWorkerRegistration();
                // sw.showNotification("Notification title", {
                //     body: "This is a notification, yes."
                // });
                // new Notification("Notification title", {
                //     body: "This is a notification"
                // });
            } catch (e) {
                alert(e);
            }
        }, 1000);
    }

    return (
        <>
            <h4>Browser notification</h4>
            {permissionGranted ? "Permission OK" : "Permission ???"}
            <button className="btn btn-theme-red py-1 px-2" onClick={sendNotification}>
                Send me a notification after 1 sec
            </button>
            <h4 className="mt-3">Push notification</h4>
            <p>This requires some more work...</p>
        </>
    );
}

export default Location;