import { actions } from ".";
import api from "../api";

export const spinTestAction = () => {
    return async (dispatch: (arg0: { type: string; payload: {}; }) => void) => {
        dispatch(actions.inProgressAction("spintest"));
        try {
            let res = await api.get("/api/sleep/3");
        } catch (e) {

        }
        dispatch(actions.notInProgressAction("spintest"));
    }
}
