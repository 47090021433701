import { AnyAction, combineReducers } from 'redux';
import { actions } from '../actions';
import { State } from '../types';

const defaultState: State = {
    settings: {
        dataLoaderHasRun: false,
        appInstallationId: null,
        socketStatus: "disconnected",
    },
    applicationStatus: {
        applicationWindowOpen: false,
        applicationName: "",
        openWindows: [],
        workstations: [],
    },
    userStatus: {
        installationActive: false,
        permissions: [],
    },
    inProgress: [],
    notes: {
        archive: [],
    }
}

function settingsReducer(state: State["settings"] = defaultState.settings, action: AnyAction): State["settings"] {
    if (actions.updateSettingsAction.match(action)) {
        return { ...state, ...action.payload };
    }
    if (actions.setSettingAction.match(action)) {
        return { ...state, ...action.payload };
    }
    if (actions.emitAction.match(action)) {
        state.socket?.emit(action.payload.name)
    }
    return state;
}

function applicationStatusReducer(state: State["applicationStatus"] = defaultState.applicationStatus, action: AnyAction): State["applicationStatus"] {
    if (actions.openApplicationWindowAction.match(action)) {
        return { ...state, applicationWindowOpen: true, applicationName: action.payload.name };
    }
    if (actions.closeApplicationWindowAction.match(action)) {
        return { ...state, applicationWindowOpen: false, applicationName: "" };
    }
    if (actions.openWindowAction.match(action)) {
        if (state.openWindows.indexOf(action.payload.name) > -1) {
            return state;
        }
        return { ...state, openWindows: [...state.openWindows, action.payload.name] }
    }
    if (actions.closeWindowAction.match(action)) {
        let newOpenWindows = [...state.openWindows];
        newOpenWindows.splice(newOpenWindows.indexOf(action.payload.name), 1)
        return { ...state, openWindows: newOpenWindows }
    }
    if (actions.workstationUpdateAction.match(action)) {
        console.log("WS UPDATE:", action.payload);
        return { ...state, workstations: action.payload.workstations}
    }
    return state;
}

function userStatusReducer(state: State["userStatus"] = defaultState.userStatus, action: AnyAction): State["userStatus"] {
    if (actions.updateUserStatusAction.match(action)) {
        return { ...state, ...action.payload }
    }
    return state;
}

function inProgressReducer(state: State["inProgress"] = defaultState.inProgress, action: AnyAction): State["inProgress"] {
    if (actions.inProgressAction.match(action)) {
        return [...state, action.payload]
    }
    if (actions.notInProgressAction.match(action)) {
        let newState = [...state]
        newState.splice(newState.indexOf(action.payload), 1)
        return newState
    }
    return state;
}

function notesReducer(state: State["notes"] = defaultState.notes, action: AnyAction): State["notes"] {
    if (actions.updateNotesAction.match(action)) {
        return {...state, archive: action.payload}
    }
    // TODO: Open
    // TODO: Save
    // TODO: Close
    return state;
}

export default combineReducers({
    settings: settingsReducer,
    applicationStatus: applicationStatusReducer,
    userStatus: userStatusReducer,
    inProgress: inProgressReducer,
    notes: notesReducer,
});
