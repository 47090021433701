import * as THREE from 'three'
import React, { useRef, useState } from 'react'
import { Canvas, useFrame, ThreeElements } from '@react-three/fiber'

interface Props {
}
const ThreeD: React.FC<Props> = () => {

    function Box(props: any) {
        const mesh = useRef<THREE.Mesh>(null!)
        useFrame((state, delta) => (mesh.current.rotation.y += (delta / 2)))
        return (
            <mesh
                {...props}
                ref={mesh}
                scale={3}
            >
                <boxGeometry args={[.1, 1, 1]} />
                <meshStandardMaterial color={"#800000"} />
            </mesh >
        )
    }

    return (
        <>
            <div>Collectibles or something</div>
            <Canvas style={{ width: "100px", height: "100px", borderRadius: "5px", backgroundColor: "#320000" }} className="d-inline-block me-2">
                {/* <ambientLight /> */}
                <pointLight position={[10, 10, 10]} />
                <Box position={[0, 0, 0]} />
                {/* <Box position={[1.2, 0, 0]} /> */}
            </Canvas>
            <Canvas style={{ width: "100px", height: "100px", borderRadius: "5px", backgroundColor: "#320000" }} className="d-inline-block me-2">
                <ambientLight intensity={.25} />
                <pointLight position={[10, 10, 10]} />
                <Box position={[0, 0, 0]} />
                {/* <Box position={[1.2, 0, 0]} /> */}
            </Canvas>
        </>
    );
}

export default ThreeD;