import React, { useEffect, useState } from 'react'

interface Props {
}
const Location: React.FC<Props> = () => {

    let [latitude, setLatitude] = useState<number | null>(null);
    let [longitude, setLongitude] = useState<number | null>(null);
    let [watching, setWatching] = useState<boolean>(false);
    let [watchId, setWatchId] = useState<number>(0);

    const positionUpdated = (position: GeolocationPosition) => {
        console.log("Position updated");
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
    }

    useEffect(() => {
        console.log("Location:")
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(positionUpdated);
        }
    });

    const toggleWatching = () => {
        if (watching) {
            stopWatching();
        }
        else {
            startWatching()
        }
    }
    const startWatching = () => {
        if (navigator.geolocation) {
            setWatchId(navigator.geolocation.watchPosition(positionUpdated));
        }
        setWatching(true);
    }
    const stopWatching = () => {
        if (navigator.geolocation) {
            navigator.geolocation.clearWatch(watchId);
        }
        setWatching(false);
    }

    return (
        <>
            Lat: {latitude ?? "-"} <br />
            Long: {longitude ?? "-"} <br />
            <button className="btn btn-theme-red py-1 px-2" onClick={toggleWatching}>
                {watching ? "Stop watching" : "Start watching"}
            </button>
        </>
    );
}

export default Location;