export const getAppInstallationId = (): string => {
    const url = window.location.hash.split('/');
    let appInstallationId = "";
    if (url.length > 2) {
        appInstallationId = url[2]
        if (appInstallationId === "null") {
            const localId = localStorage.getItem("appInstallationId");
            if (localId !== null) {
                appInstallationId = localId;
            } else {
                appInstallationId = getRandomInstallationId();
                localStorage.setItem("appInstallationId", appInstallationId);
            }
        }
    }
    return appInstallationId;
}

export const setSocketId = (socketId: string) => {
    localStorage.setItem("socketId", socketId);
}

export const getSocketId = () => {
    return localStorage.getItem("socketId");
}

const getRandom32Hex = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const hexString = array[0].toString(16);
    const paddedHexString = hexString.padStart(8, '0');
    return paddedHexString;
}

export const getRandomInstallationId = () => {
    let r = "";
    for (let i = 0; i < 8; i++) {
        r += getRandom32Hex()
    }
    return r;
}
