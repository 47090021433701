import { AppSettings } from "../types";
import { Note, UserStatus } from "../../../types";
import api from "../api";
import { createAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { getAppInstallationId } from "../helpers/authorizationHelpers";

export const actions = {
    updateSettingsAction: createAction<AppSettings>("UPDATE_SETTINGS"),
    setSettingAction: createAction<Partial<AppSettings>>("UPDATE_SETTINGS"),
    openApplicationWindowAction: createAction<{ name: string }>("OPEN_APPLICATION_WINDOW"),
    closeApplicationWindowAction: createAction("CLOSE_APPLICATION_WINDOW"),
    openWindowAction: createAction<{ name: string }>("OPEN_WINDOW"),
    closeWindowAction: createAction<{ name: string }>("CLOSE_WINDOW"),
    updateUserStatusAction: createAction<UserStatus>("UPDATE_USER_STATUS"),
    inProgressAction: createAction<string>("IN_PROGRESS"),
    notInProgressAction: createAction<string>("NOT_IN_PROGRESS"),
    updateNotesAction: createAction<Note[]>("UPDATE_NOTES"),
    emitAction: createAction<{ name: string, data?: any }>("EMIT"),
    workstationUpdateAction: createAction<{ workstations: string[] }>("UPDATE_WORKSTATIONS"),
}

export const loadSettings = () => {
    return async (dispatch: (arg0: any) => void) => {
        console.log("Loading settings action...")
        const settings: AppSettings = {
            dataLoaderHasRun: true,
            appInstallationId: getAppInstallationId(),
            socketStatus: "disconnected",
        }
        dispatch(actions.updateSettingsAction(settings));
    }
}

export const setSetting = (s: Partial<AppSettings>) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.setSettingAction(s));
    }
}

export const openApplicationWindow = (name: string) => {
    return async (dispatch: (arg0: any) => void) => {
        console.log(`Opening application ${name}...`)
        dispatch(actions.openApplicationWindowAction({ name }));
    }
}

export const closeApplicationWindow = (name: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.closeApplicationWindowAction());
    }
}

export const openWindow = (name: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.openWindowAction({ name }));
    }
}

export const closeWindow = (name: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.closeWindowAction({ name }));
    }
}

export const fetchUserStatus = () => {
    return async (dispatch: (arg0: any) => void) => {
        let res = await api.get("/api/userstatus");
        if (res.status !== 200) {
            dispatch(displayResponseError(res));
        }
        dispatch(actions.updateUserStatusAction(res.data))
    }
}

export const displayResponseError = (res: AxiosResponse<any, any>) => {
    return async (dispatch: (arg0: any) => void) => {
        console.error("ERROR", res)
        alert(`Error ${res.status}: ${res.data ?? res.statusText}`);
    }
}

export const setWorkstations = (workstations: string[]) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.workstationUpdateAction({ workstations }));
    }
}