import React from 'react';
import { faEye, faLock, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { faMoon } from '@fortawesome/free-regular-svg-icons'
import { useDispatch } from 'react-redux';
import { desktopIconClass } from '..';
import { lockWorkstation, putToSleep, shutdown, wakeUp } from '../../../actions/api';
import Icon from './utils/Icon';

interface Props {
}
const Archon: React.FC<Props> = () => {
    const dispatch: any = useDispatch();

    return (
        <>
            <div className="">

                <button onClick={() => { dispatch(lockWorkstation("archon")) }} className={desktopIconClass}>
                    <span className="icon" style={{ color: "#1E88E5" }}>
                        <Icon icon={faLock} inProgressId="lockWorkstation_archon" />
                    </span>
                    <span className="title">
                        Lock
                    </span>
                </button >

                <button onClick={() => { dispatch(putToSleep("archon")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faMoon} inProgressId="putToSleep_archon" />
                    </span>
                    <span className="title">
                        Sleep
                    </span>
                </button>

                <button onClick={() => { dispatch(shutdown("archon")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faPowerOff} inProgressId="shutdown_archon" />
                    </span>
                    <span className="title">
                        Shutdown
                    </span>
                </button>

                <button onClick={() => { dispatch(wakeUp("archon")) }} className={desktopIconClass}>
                    <span className="icon" style={{ color: "#1E88E5" }}>
                        <Icon icon={faEye} inProgressId="wakeUp_archon" />
                    </span>
                    <span className="title">
                        Wake up
                    </span>
                </button>

            </div>

        </>
    );
}

export default Archon;