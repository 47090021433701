import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask, faKey, faSeedling, faBars, faHouseUser, faGear, faCircleInfo, faTimes, faLocationDot, faQrcode, faCube, faSpinner, faSync } from '@fortawesome/free-solid-svg-icons'
import { faDotCircle, faAddressBook, faBell, faClipboard } from '@fortawesome/free-regular-svg-icons'
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { actions, openApplicationWindow } from "../../actions";
import { State } from "../../types";
import { UserStatus } from "../../../../types";
import { Button } from "react-bootstrap";
import { spinTestAction } from "../../actions/testActions";

interface Props {
}
const Settings: React.FC<Props> = () => {
    const settings: any = useSelector((store: State) => store.settings);
    const inProgress: any = useSelector((store: State) => store.inProgress);
    const userStatus: UserStatus = useSelector((store: State) => store.userStatus);
    const dispatch: any = useDispatch();

    return (
        <>
            <div className="py-2">
                <h4>Settings</h4>

                <p>{JSON.stringify(settings)}</p>
                <p>{JSON.stringify(userStatus)}</p>
                <Button
                    disabled={inProgress.indexOf("spintest") > -1}
                    className="px-2 py-1"
                    variant="secondary"
                    onClick={() => { dispatch(spinTestAction()) }}
                >
                    Test spinner
                    {inProgress.indexOf("spintest") > -1 ? <FontAwesomeIcon className="ms-2" icon={faSync} size={"1x"} spin={true} /> : null}
                </Button>
                <span className="ms-2">
                </span>
                <div>
                    {inProgress.join(", ")}
                </div>
            </div>
        </>
    );
}

export default Settings;