import { faDesktop, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import {
    HashRouter as Router,
    Route,
    Redirect,
    Switch,
} from "react-router-dom";
import Desktop from "./Desktop";
import Header from "./Header";
import Settings from "./Settings";
import ResetPassword from "./ResetPassword";

function App() {

    const getRandom32Hex = () => {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        const hexString = array[0].toString(16);
        const paddedHexString = hexString.padStart(8, '0');
        return paddedHexString;
    }

    const getRandomInstallationId = () => {
        let r = "";
        for (let i = 0; i < 8; i++) {
            r += getRandom32Hex()
        }
        return r;
    }

    return (
        <>
            <div className="container p-0">
                <Router>
                    <Switch>
                        <Route exact path="/installed" ><Redirect to="/installed/0/" /></Route>
                        <Route path="/installed/:installationId/">
                            <Header />
                            <div className="px-2">
                                <Route exact path="/installed/:installationId/">
                                    <Desktop />
                                </Route>
                                <Route exact path="/installed/:installationId/settings">
                                    <Settings />
                                </Route>
                                <Route exact path="/installed/:installationId/about">
                                    <h4>About</h4>
                                </Route>
                            </div>
                        </Route>
                        <Route exact path="/">
                            <div className="p-2">
                                <Modal show={true} onHide={() => { }} >
                                    <Modal.Body>
                                        <FontAwesomeIcon icon={faMobileScreen} className="me-2 text-orange" />
                                        Install web app on your mobile device by adding it to your Home Screen.
                                        <br />
                                        <br />
                                        <FontAwesomeIcon icon={faDesktop} className="me-2 text-orange" />
                                        To use the app in browser, use <a target="_blank" href={`/#/installed/null/`} rel="noreferrer">this link</a>.
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Route>
                        <Route exact path="/resetpassword/:emailAddress/:code" >
                            <ResetPassword />
                        </Route>
                    </Switch>
                </Router>
            </div>
        </>
    );
}

export default App;